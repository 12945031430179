@font-face {
    font-family: 'Roboto';
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Futura';
    src: url('../assets/fonts/Futura-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }