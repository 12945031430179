.home-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 18px;
  background-color:#ffffff;
  line-height: 1.1;
  color: black;
  font-family:'Roboto';
  margin-top: 130px;
}

.video{
  margin-top: 25px; 
}

.leaders{
  background-color: rgb(252, 233, 140);
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0px;
  border-radius: 35px;
  background-position: center;
  position: relative;
  background-size: cover;
  width:100%;
  height: 400px;
}

.leader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  opacity: 1;
}

.leaders ul{
  list-style-type: none;
  width:50%;
  font-style: italic;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 100px;
}

leader-text {
  flex: 1;
}


.leader-text ul li {
  line-height: 1.1; /* Adjust as needed; smaller values decrease spacing */
}

.leader-image {
  margin-right: 200px; 
  margin-top: 10px;
  flex-shrink: 0;
}

.leader-image img {
  border-radius: 20px;
  object-fit: cover;
  width: 350px; /* Set desired width */
  height: 350px; /* Set desired height */
}

.dot-navigation {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: -40px;

}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #333;
}

/* Smooth Transition for Leader Profile */
.leaders{
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.address{
  background-color:rgb(250, 206, 63) ;
}

.contact-sec{
  background-color: rgb(250, 206, 63);
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom:-10px;
}

.address h2{
  margin-left: 60px;
  text-align: left;
  color: black;
  font-size: 20px;
  font-family: 'Roboto';
  margin-top: 30px;
}

.address p{
  text-align: left;
  margin-left: 60px;
  color: black;
  font-size: 15px;
  font-family:'Roboto';
  line-height: 1.4;
}


.lists ul{
  list-style-type: none;
  display: flex;
  gap: 40px;
  font-size: 16px;
}

.lists li{
  font-weight: 700;
  font-family:"Roboto";
  margin-top: -200px;
  font-size: 15px;
}

.lists li:first-child {
  margin-left: 390px; /* Move the first item 1200px from the left */
}

.topic ul{
  list-style-type: none;
  font-weight: bold;
  font-family:'Roboto';
  margin-top: -150px;
  
  margin-left: 390px;
}

.topic ul li{
  margin-bottom: 15px;
  font-size: 15px;
}

.phone{
  margin-top:-100px;
  margin-left: 760px;
  margin-bottom: 92px;
}

.mail{
  margin-top: -120px;
  margin-left: 795px;
}

.whatsapp{
  margin-top:-28px;
  margin-left: 830px;
}

.facebook{
  margin-top: -28px;
  margin-left: 895px;
}

.twitter{
  margin-top: -25px;
  margin-left: 925px;
}

.instagram{
  margin-top: -27px;
  margin-left: 955px;
}

.map{
  margin-top: -28px;
  margin-left: 1060px;
  margin-bottom: 200px;
}

.slider-container {
  overflow: hidden;
  width: 100%;
  height: 900px;
  position: relative;
  margin-bottom: -190px;
}

.slides img {
  display: flex;
  height: 700px;
  width: 100px;
  transition: transform 0.5s ease;
}

.slide {
  min-width: 100%;
  height: 800px;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  margin-bottom: -20px;
}

.left-image{
  margin-left: 30px;
  margin-right:180px ;
}

.right-image{
  margin-left: 10px;
}

.left-image,
.right-image {
  height: 650px;
  width: 700px; /* Adjust width as needed */
}

.project-images {
  position: relative;
  width: 100%;
  height:500px;
  height: 400px;
  overflow: hidden;
}

.project-slide {
  width: 100%;
  height: 100%;
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
}

.project-slide img {
  display: block;
  width: 500px;
  height: 500px;
}

.arrow {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  font-size: 20px;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.8);
}



