.ongoing-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 20px;
  background-color: #ffffff;
  margin-top: 150px;
}

.project-section {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background: #f9f9f9;
}

.image-slider {
  flex: 1;
  overflow: hidden;
  position: relative;
  max-width: 50%;
  border: 2px solid #ccc;
}

.slider-wrapper {
  display: flex;
  transition: transform 1.5s ease-in-out;
}

.project-image {
  width: 100%;
  height: auto;
  min-width: 100%; /* Ensure images take full width */
}

.details-and-amenities {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-details {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  margin-bottom: -20px;
}

.amenities-section {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
}

.amenities-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.amenity-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.amenity-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
