.ChatPopup-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  color: rgb(0, 0, 0);
}

.chatbox {
  width: 220px;
  height: 275px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chatbox-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f8bf30;
  border-radius: 8px 8px 0 0;
  color: rgb(0, 0, 0);
  height:20px;
}

.chatbox-header h4{
  color: rgb(0, 0, 0);
  margin-left: 15px;
  margin-right: 20px;
  margin-top: 3px;
  font-size: 18px;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
}

.chatbox-header p{
  margin-bottom: 10px;
  font-size: 18px;
  margin-left:10px;
}

.chatbox-content {
  padding: 10px 0;
}

.chat-form {
  display: flex;
  flex-direction: column;
  color: rgb(0, 0, 0);
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  font-size: 15px;
  margin-left: 10px;
  color: rgb(0, 0, 0);
  font-weight: 550;
}

.form-group p{
  margin-top: 2px;
  margin-left: 10px;
  margin-bottom: 3px;
  font-size: 15px;
}

.form-group input {
  width: 170px;
  height:10px;
  margin-left: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: -3px;
}

.submit-btn {
  background-color: #ffc531;
  color: rgb(0, 0, 0);
  border: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: -5px;
  width:27%;
  text-align: center;
  margin: auto;
  display: flex;
}

.submit-btn:hover {
  background-color: #f5b609;
}

.submit-btn b{
  margin-left: -04px;
}
.ChatPopup-icon {
  background-color: #f6ad05;
  border: none;
  border-radius: 50%;
  padding: 15px;
  color: white;
  cursor: pointer;
  box-shadow: 0 10px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 10px;
  right: 20px;
}

.ChatPopup-icon:hover {
  background-color: #e9bc06;
}
