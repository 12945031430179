header {
    position: fixed; /* Keeps the header fixed at the top */
    top: 0; /* Ensures the header sticks to the top of the viewport */
    left: 0; /* Aligns the header with the left edge */
    width: 100%; /* Makes the header span the full width of the page */
    z-index: 1000; /* Ensures the header is above other content */
    background-color: #fff; /* Optional: Background color to avoid overlap visibility issues */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
    margin-top: -10px;
  }

.image {
  flex: 0 0 auto;
  margin-left: 60px;
  margin-top: 15px; /* Prevent logo from shrinking */
}

.image img {
  animation: pop-in 0.8s ease-out;
}

.gif-container{
  margin-top: -60px;
  margin-left: 200px;
  width: 300px;
  height: 90px;
}

.gif-container img {
  border-radius: 20px; /* Adjust the value to control how round the corners are */
  overflow: hidden;    /* Ensures the image corners are clipped when rounded */
}

.nav-bar{
  display: flex;
  gap:50px;
  margin-top: -40px;
  background-color: rgb(251, 211, 78);
  height: 30px;
}

.nav-bar .nav-link{
  font-size: 15px;
  display: flex;
  margin-left: 55px;
  text-decoration: none;
  color: black;
  margin-top: 4px;
}

.nav-bar .projects-link{
  font-size: 15px;
  color: black;
  cursor: pointer;
  margin-left: 55px;
  margin-top: 4px;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 15px;
}

.dropdown-menu a {
  display: block;
  padding: 10px 10px;
  text-decoration: none;
  color:#000000;
  font-size: 15px;
  margin-left: 20px;

  gap:20px
}

.dropdown-menu a:hover {
  background-color: #f2dc72; /* Change background on hover */
}