.about-md {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    line-height: 1.8;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 150px;
  }
  
  .about-md h2 {
    font-size: 28px;
    color: #2c3e50;
    text-align: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }
  
  .para-md p {
    font-size: 16px;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .para-md p:first-letter {
    font-size: 20px;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .para-md p br {
    display: none;
  }
  
  .para-md p + p {
    margin-top: 15px;
  }
  
  .para-md p span {
    font-weight: bold;
    color: #3498db;
  }
  
  @media (max-width: 768px) {
    .about-md {
      padding: 15px;
    }
  
    .about-md h2 {
      font-size: 24px;
    }
  
    .para-md p {
      font-size: 14px;
    }
  }