body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

nav ul {
  padding: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  gap: 15px;
}

nav ul li {
  list-style-type: none;
}

nav ul li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

nav ul li a:hover {
  color: #007bff;
}
