/* General body styling */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6;
}

/* Full page container */
#about {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 150px;
}

/* Header section */
.head h2 {
  font-size: 26px;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 3px solid #3498db;
  display: inline-block;
  padding-bottom: 5px;
}

/* Paragraphs */
.para p, .discover p {
  margin-bottom: 20px;
  text-align: justify;
  font-size: 16px;
}

/* List styling */
.list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list ul li {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
}

.list ul li::before {
  content: '✔';
  color: #3498db;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 0;
}

/* Vision, Mission, and Goals sections */
.about-us .vision, .about-us .mission, .about-us .goal {
  margin-bottom: 30px;
}

.about-us h3 {
  font-size: 24px;
  color: #2c3e50;
  border-bottom: 2px solid #3498db;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.about-us p {
  font-size: 16px;
  text-align: justify;
  margin-bottom: 15px;
}

.goal ul {
  margin-left: 20px;
  padding-left: 10px;
}

.goal ul li {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #about {
    padding: 15px;
  }

  .head h2 {
    font-size: 28px;
  }

  .para p, .discover p, .list ul li, .about-us p {
    font-size: 14px;
  }
}
