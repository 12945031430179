/* General body styling */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.6;
  margin-top: 200px;
}

/* Full page container */
#career {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header section */
.crown h2 {
  font-size: 36px;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 3px solid #3498db;
  display: inline-block;
  padding-bottom: 5px;
}

/* Paragraphs */
.paragraph p {
  margin-bottom: 20px;
  text-align: justify;
  font-size: 16px;
}

/* Section headings */
.why p {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 15px;
}

.growth ul, .opening ul, .response ul, .qualify ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.growth ul li, .opening ul li, .response ul li, .qualify ul li {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  margin-left: 20px;
}

.growth ul li::before, .response ul li::before, .qualify ul li::before {
  content: '✔';
  color: #3498db;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 0;
}

.opening ul li::before {
  content: '📌';
  color: #e74c3c;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  #career {
    padding: 15px;
  }

  .crown h2 {
    font-size: 28px;
  }

  .paragraph p, .growth ul li, .opening ul li, .response ul li, .qualify ul li {
    font-size: 14px;
 
  }
}
