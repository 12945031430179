.completed-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    padding: 20px;
    height: 100vh;
    margin-bottom: 20px;
    margin-top: 150px;
  }
  
  .site-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .site-box-left {
    grid-column: 1;
  }
  
  .site-box-right {
    grid-column: 2;
  }
  
  .site-image {
    width: 600px;
    height: 300px;
    border-radius: 10px;
    margin-bottom: 15px;
  }
  
  .site-details h3 {
    margin: 10px 0;
    font-size: 23px;
    color: #000000;
    text-align: center;
  }
  
  .site-details p {
    font-size: 20px;
    color: #000000;
    text-align: center;
  }
  